@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-grey-800 bg-grey-100;
  }
}

@layer components {
  .btn-primary {
    @apply bg-primary-main text-primary-contrastText hover:bg-primary-dark;
  }
  
  .btn-secondary {
    @apply bg-secondary-main text-secondary-contrastText hover:bg-secondary-dark;
  }
}
